<template>
  <div>
    <div class="toolbar">
      <el-input
        v-show="show"
        v-model="loc"
        :placeholder="$l('common.selectLoc','点击地图选择位置点')"
        disabled
        style="width: 300px">
      </el-input>
    </div>
    <div id="map"></div>
  </div>
</template>

<script>
  import MapLoader from "@/util/map";

  export default {
    components: {},
    props: {
      editable: {
        type: Boolean,
        default: true,
      },
      lng: [Number, String],
      lat: [Number, String],
      model:Object,
      move:Boolean,
      onlyOne:Boolean,
      show:{
        type:Boolean,
        default: false,
      },
      signRadius:{
        type:[Number,String],
        default:500,
      },
      coordinate:Object,
    },
    data() {
      return {
        AMap: null,
        map: null,
        centerPoint: null,
        poiMarkerTemp: null,
        loc: "",
        pathParam:[
          [122.173639,37.421945],
          [122.158597,37.425082],
          [122.152653,37.435815],
          [122.158597,37.425082],
          [122.173639,37.421945],
        ],
        lait:[122.173035,37.42041],
        makerInfo:{
          name:"电梯1",
          content:"内容1",
          img:"http://tpc.googlesyndication.com/simgad/5843493769827749134",
          person:"周宇",
          phone:123456,
          area:"威海市经济技术开发区九龙城智慧谷",
          path:"#/elevator/Elevatordetail?elevatorId=",
          id:58,
        },
      };
    },
    watch:{
      coordinate:{
        handler(params) {
          if (this.map) {
            this.map.clearMap();
          }
          console.log(params,74747);
          this.initMap(params);

        },
        deep:true,
        immediate:true,
      },
    },
    mounted() {
      // this.initMap();
    },
    methods: {
      initMap(params) {
        MapLoader().then(AMap => {
          this.AMap = AMap;
          let mapOption = {};
          if (this.lng && this.lat) {
            mapOption.center = [this.lng, this.lat];
            mapOption.zoom = 16;
          } else {
            // this.lng=122.173639;
            // this.lat=37.421945;
            mapOption.center = [params.longitude,params.latitude];
            mapOption.zoom = 16;
          }
          this.map = new AMap.Map("map", mapOption);
          this.map.addControl(new AMap.Geolocation({
            position:{bottom:"100px",right:"20px"},
          })); // 定位
          // this.map.setDefaultCursor("pointer");
          // if (this.move) this.moveAnimation(AMap);//开启轨迹动画


          // this.pathParam.forEach(item=>{
          //   this.addPoint(item[0],item[1]);
          // });
          console.log(params.arrive.lng,107,params.longitude);
          if (params.arrive.lng) {
            this.addPoint(params.arrive.lng,params.arrive.lat,{...params.arrive,realName:params.name,type:"到达"});
          }
          if (params.assign.lng) {
            this.asignPoint(params.assign.lng,params.assign.lat,{...params.assign,realName:params.name,type:"认领"});

          }
          if (params.longitude) {
            this.realPoint(params.longitude,params.latitude,{...params.assign,realName:params.name});
          }
          if (!params.arrive.lng&&!params.assign.lng&&!params.longitude) {
            this.map.clearMap();
          }
        });

      },
      addPoint(lng, lat,params) {
        console.log(123,lng, lat);
        if (lng && lng) {
          let marker = new this.AMap.Marker({
            position: new this.AMap.LngLat(lng, lat),
            icon: require("../assets/repari.png"),
            offset: new this.AMap.Pixel(-12, -33),
            zIndex: 2000,
            topWhenClick: true,
          });

          this.info(marker,params);
          marker.on("click", ()=>{
            this.info(marker,params);
          });
          if (this.centerPoint&&this.onlyOne) {
            this.map.remove(this.centerPoint);
          }
          this.centerPoint = marker;
          marker.setMap(this.map);



        }
      },
      asignPoint(lng, lat,params) {
        console.log(123,lng, lat);
        if (lng && lng) {
          let marker = new this.AMap.Marker({
            position: new this.AMap.LngLat(lng, lat),
            icon: require("../assets/repari.png"),
            offset: new this.AMap.Pixel(-12, -33),
            zIndex: 2000,
            topWhenClick: true,
          });

          this.info(marker,params);
          marker.on("click", ()=>{
            this.info(marker,params);
          });
          if (this.centerPoint&&this.onlyOne) {
            this.map.remove(this.centerPoint);
          }
          this.centerPoint = marker;
          marker.setMap(this.map);


        }
      },
      realPoint(lng, lat,params) {
        console.log(123,lng, lat);
        if (lng && lng) {
          let marker = new this.AMap.Marker({
            position: new this.AMap.LngLat(lng, lat),
            icon: require("../assets/realestate.png"),
            offset: new this.AMap.Pixel(-12, -33),
            zIndex: 2000,
            topWhenClick: true,
          });

          let circle = new this.AMap.Circle({
            center: new this.AMap.LngLat(lng, lat), // 圆心位置
            radius: this.signRadius,  //半径
            strokeColor: "#F33",  //线颜色
            strokeOpacity: 1,  //线透明度
            strokeWeight: 3,  //线粗细度
            fillColor: "#ee2200",  //填充颜色
            fillOpacity: 0.35, //填充透明度
          });

          this.realInfo(marker,params);
          marker.on("click", ()=>{
            this.realInfo(marker,params);
          });
          if (this.centerPoint&&this.onlyOne) {
            this.map.remove(this.centerPoint);
          }
          this.centerPoint = marker;
          marker.setMap(this.map);
          this.map.add(circle);

        }
      },
      initSearch() {
        let AMap = this.AMap;
        AMap.plugin(["AMap.AutoComplete", "AMap.PlaceSearch"], () => {//异步同时加载多个插件
          let auto = new AMap.AutoComplete({
            input: "searchInput",
          });
          let placeSearch = new AMap.PlaceSearch({
            map: this.map,
          });
          auto.on("select", e => {
            placeSearch.setCity(e.poi.adcode);
            placeSearch.search(e.poi.name);  //关键字查询查询
          });//注册监听，当选中某条记录时会触发
          placeSearch.on("markerClick", e => {
            if (this.poiMarkerTemp) {
              this.poiMarkerTemp.show();
            }
            this.poiMarkerTemp = e.marker;
            e.marker.hide();
            this.addPoint(e.data.location.lng, e.data.location.lat);
          });
        });
      },
      info(marker,parmas) {
        // let img = <img src="this.makerInfo.img"/>
        console.log(marker,192,parmas);
        var title = "认领人："+parmas.name,
            content = [];
        content.push("打卡类型："+parmas.type);
        content.push("打卡时间："+parmas.time);
        var infoWindow = new this.AMap.InfoWindow({
          isCustom: true,  //使用自定义窗体
          content: this.createInfoWindow(title, content.join("<br/>")),
          offset: new this.AMap.Pixel(16, -45),
          autoMove:true,
        });
        console.log(marker.getPosition(),2424242424);
        // 打开信息窗体
        infoWindow.open(this.map,marker.getPosition());
      },
      realInfo(marker,parmas) {
        // let img = <img src="this.makerInfo.img"/>
        console.log(marker,241,parmas);
        var title = "楼盘："+parmas.realName,
            content = [];
        // content.push("认领人："+ (parmas.name?parmas.name:"暂无人认领"));
        var infoWindow = new this.AMap.InfoWindow({
          isCustom: true,  //使用自定义窗体
          content: this.realCreateInfoWindow(title, content.join("<br/>")),
          offset: new this.AMap.Pixel(16, -45),
          autoMove:true,
        });

        // 打开信息窗体
        infoWindow.open(this.map,marker.getPosition());
      },
      startAnimation(marker) {
        console.log(marker,199);
        // const option={
        //   path:this.pathParam,
        //   speed:200,
        //   circlable:true,
        // };
        marker.moveAlong(this.pathParam, {
          // 每一段的时长
          duration: 10000,//可根据实际采集时间间隔设置
          // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
          autoRotation: true,
          circlable:true,
        });
      },
      moveAnimation(AMap) {
        AMap.plugin("AMap.MoveAnimation", ()=>{
          let car = new AMap.Marker({
            position: [122.173639,37.421945],
            icon: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
            offset: new AMap.Pixel(-13, -26),
          });
          car.setMap(this.map);
          var polyline = new AMap.Polyline({
            path: this.pathParam,
            showDir:true,
            strokeColor: "#28F",  //线颜色
            // strokeOpacity: 1,     //线透明度
            strokeWeight: 6,      //线宽
            // strokeStyle: "solid"  //线样式
          });
          polyline.setMap(this.map);
          car.on("moving", function(e) {
            passedPolyline.setPath(e.passedPath);
          });
          this.startAnimation(car);

          var passedPolyline = new AMap.Polyline({
            // path: lineArr,
            strokeColor: "#AF5",  //线颜色
            // strokeOpacity: 1,     //线透明度
            strokeWeight: 6,      //线宽
            // strokeStyle: "solid"  //线样式
          });
          passedPolyline.setMap(this.map);
          // this.startAnimation(car);
        });

      },
      createInfoWindow(title, content) {
        var info = document.createElement("div");
        info.className = "custom-info input-card content-window-card";
        //可以通过下面的方式修改自定义窗体的宽高
        info.style.width = "200px";
        // 定义顶部标题
        var top = document.createElement("div");
        var titleD = document.createElement("div");
        var closeX = document.createElement("img");
        top.className = "info-top";
        titleD.innerHTML = title;
        closeX.src = "https://webapi.amap.com/images/close2.gif";
        closeX.onclick = this.closeInfoWindow;

        top.appendChild(titleD);
        top.appendChild(closeX);
        info.appendChild(top);

        // 定义中部内容
        var middle = document.createElement("div");
        middle.className = "info-middle";
        middle.style.backgroundColor = "white";
        middle.innerHTML = content;
        info.appendChild(middle);

        // 定义底部内容
        var bottom = document.createElement("div");
        bottom.className = "info-bottom";
        bottom.style.position = "relative";
        bottom.style.top = "0px";
        bottom.style.margin = "0 auto";
        var sharp = document.createElement("img");
        sharp.src = "https://webapi.amap.com/images/sharp.png";
        bottom.appendChild(sharp);
        info.appendChild(bottom);
        return info;
      },
      realCreateInfoWindow(title, content) {
        var info = document.createElement("div");
        info.className = "custom-info input-card content-window-card";
        //可以通过下面的方式修改自定义窗体的宽高
        info.style.width = "200px";
        // 定义顶部标题
        var top = document.createElement("div");
        var titleD = document.createElement("div");
        var closeX = document.createElement("img");
        top.className = "info-top";
        titleD.innerHTML = title;
        closeX.src = "https://webapi.amap.com/images/close2.gif";
        closeX.onclick = this.closeInfoWindow;

        top.appendChild(titleD);
        top.appendChild(closeX);
        info.appendChild(top);

        // 定义中部内容
        var middle = document.createElement("div");
        middle.className = "info-middle";
        middle.style.backgroundColor = "white";
        middle.innerHTML = content;
        info.appendChild(middle);

        // 定义底部内容
        var bottom = document.createElement("div");
        bottom.className = "info-bottom";
        bottom.style.position = "relative";
        bottom.style.top = "0px";
        bottom.style.margin = "0 auto";
        var sharp = document.createElement("img");
        sharp.src = "https://webapi.amap.com/images/sharp.png";
        bottom.appendChild(sharp);
        info.appendChild(bottom);
        return info;
      },
      closeInfoWindow() {
        this.map.clearInfoWindow();
      },
      goToDetail() {
        this.$router.push(this.makerInfo.path+this.makerInfo.id);
      },
    },
  };
</script>

<style lang="scss" scoped>
.toolbar {
  position: relative;
  z-index: 2004;
}

#map {
  margin: 10px;
  width: 100%;
  height: calc(94vh - 360px);
}

#searchInput {
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  color: #606266;
  padding: 0 15px;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
  margin-bottom: 10px;
  width: 280px;
}

.inputLabel {
  color: #333;
  font-size: 13px;
  margin-right: 5px;
  margin-left: 4px;
}
</style>

<style lang="scss">
.amap-sug-result {
  z-index: 3000 !important;
}

.amap-icon img {
  width: 24px;
  height: 35px;
}
.content-window-card {
  position: relative;
  box-shadow: none;
  bottom: 0;
  left: 0;
  width: auto;
  padding: 0;
}

.content-window-card p {
  height: 2rem;
}

.custom-info {
  border: solid 1px silver;
}

div.info-top {
  position: relative;
  background: none repeat scroll 0 0 #F9F9F9;
  border-bottom: 1px solid #CCC;
  border-radius: 5px 5px 0 0;
}

div.info-top div {
  display: inline-block;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  line-height: 31px;
  padding: 0 10px;
}

div.info-top img {
  position: absolute;
  top: 10px;
  right: 10px;
  transition-duration: 0.25s;
}

div.info-top img:hover {
  box-shadow: 0px 0px 5px #000;
}

div.info-middle {
  font-size: 12px;
  padding: 10px 6px;
  line-height: 20px;
}

div.info-bottom {
  height: 0px;
  width: 100%;
  clear: both;
  text-align: center;
}

div.info-bottom img {
  position: relative;
  z-index: 104;
}

span {
  margin-left: 5px;
  //font-size: 11px;
}

.info-middle img {
  float: left;
  margin-right: 6px;
}
</style>
